.pixellist .list_title{
    margin-bottom: 18px;
}

.list_title h3{
    font-size: 20px;
    font-weight: bold;
    color: orange;
    margin-left: 16px;
    margin-top: 10px;
}
#sear_fbnum{
    width: 250px;
    height: 40px;
    outline: none;
    text-indent: 15px;
    margin-top: 10px;
    margin-left: 20px;
    border: 1px solid gray;
    border-radius: 5px;
} 

.list_title button{
    padding-top: 0px;
   width: 80px;
   height: 40px;
   border: none;
   border-radius: 8px; 
   font-size: 18px;
   text-align: center;
   line-height: 40px;
   background-color: gainsboro;
   margin: 0 5px;
}
.list_title button:hover{
    font-size:20px;
    /* background-color: rgb(207, 207, 240); */
    /* color: white; */
}
.list_title button:active{
    font-size:15px;
}

.list_title .xz{
    margin-right: 300px;
    padding-top: 0px;
    background-color: green;
    color: white;
}
.list_title .sear{
    margin-left: 15px;
}


/* 保存 */
.add_table{
    width: 450px;
    padding: 10px 15px;
    border-radius: 10px;
    height: 300px;
    background-color: rgb(181, 201, 238);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.add_table tbody{
    width: 90%;
    height: 270px;
}
.add_table input{
    width: 300px;
    height: 30px;
    border: none;
    border: cadetblue 1px solid;
    outline: none;
    text-indent: 15px;
    border-radius: 10px;
}

.add_table td{
    text-align: center;
}

.add_table button{
    width: 90px;
    height: 30px;
    background-color: white;
    font-size: 18px;
    color: black;
    text-align: center;
    line-height: 30px;
    margin-top: 15px;
    margin-left: 20px;
}
.add_table button:hover{
    background-color: rgb(12, 117, 121);
    color: red;
}



/* 修改 */
.update_table{
    width: 450px;
    padding: 10px 15px;
    border-radius: 10px;
    height: 300px;
    background-color: rgb(181, 201, 238);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.update_table tbody{
    width: 90%;
    height: 270px;
}
.update_table input{
    width: 300px;
    height: 30px;
    border: none;
    border: cadetblue 1px solid;
    outline: none;
    text-indent: 15px;
    border-radius: 10px;
}

.update_table td{
    text-align: center;
}

.update_table button{
    width: 90px;
    height: 30px;
    background-color: white;
    font-size: 18px;
    color: black;
    text-align: center;
    line-height: 30px;
    margin-top: 15px;
    margin-left: 20px;
}
.update_table button:hover{
    background-color: rgb(12, 117, 121);
    color: red;
}












