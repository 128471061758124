
.productList .list_title{
    margin-bottom: 18px;
}

.list_title h3{
    font-size: 20px;
    font-weight: bold;
    color: orange;
    margin-left: 16px;
    margin-top: 10px;
}


.panel-body {
    padding: 15px;
}

.page-header {
    padding-bottom: 9px;
    margin: 40px 0 20px;
    border-bottom: 1px solid #eee;
    color: #1c84c6;
}




