.login{
    width: 100%;
    height: 100%;
    background-color: red;
    background: url(../../assets/images/3.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
}
.logincont{
    width: 600px;
    height: 450px;
    margin: 140px auto 0px auto;
    background-color: cadetblue;
    opacity: 0.8;
}
.loginhead{
    /* width: 100%;
    height: 50px;
    display: flex; */
    width: 100%;
    text-align: center;
    line-height: 50px;
    font-size: 25px;
    color: rgb(112, 72, 44);
    font-weight: bold;
    cursor: pointer;
}
/* .loginhead div{
    width: 49%;
    text-align: center;
    line-height: 50px;
    font-size: 25px;
    color: rgb(112, 72, 44);
    font-weight: bold;
    cursor: pointer;
} */
.loginhead div:hover{
    background-color: #136469;
    color: rgb(16, 134, 231);
    border: 1px solid orangered;
}
.loginhead div:first-child{
    /* border-right: 2px solid orangered; */
}
.logincontt{
    margin-top: 50px;
    width: 100%;
    height: 150px;
    position: relative;
}

