.emaillogin{
    margin-top: 50px;
    width: 100%;
    height: 150px;
    position: relative;
}

.emaillogin tr{
    width: 95%;
    height: 50px;
}
.title td{
    width: 100%;
    height: 35px;
    color: rgb(11 ,69, 119);
    font-size: 24px !important;
    padding-bottom: 20px !important;
    text-align: center;
}

.emaillogin td:first-child{
    font-size: 18px;
    width: 170px;
    height: 100%;
    text-align:center;
    line-height: 40px;
    font-weight: bold;
}

.emaillogin tr td:last-child{
    width: calc(100%-220px);
    height: 100%;
}

.emaillogin tr td input{
    border: none;
    text-indent: 15px;
    width: 75%;
    height: 35px
}

.emaillogin .logined{
    width: 300px;
    height: 35px;
    position: absolute;
    bottom: -110px;
    left: 50%;
    transform: translateX(-65%);
    
}
.logined button{
    width: 400px;
    height: 100%;
    background-color: rgb(35, 111, 121);
    font-size: 20px;
    line-height: 35px;
    color: white;
    border: none;
    cursor: pointer;
}
.logined button:hover{
    background-color: rgb(6, 66, 114);
    color: red;
}