.admin_box{
    width: 100%;
    height: 100%;
}

.admin_top_box{
    width: 100%;
    height: 80px;
    background-color:  #001529;
    border-bottom: 4px solid #504dff;
}

.admin_bottom_box{
    width: 100%;
    height: calc(100% - 108px);
    display: flex;
}

.admin_left{
    width: 168px;
    height: 103%;
    border-right: 3px solid #c1c1c1;
    background: #001529;
    overflow: auto;

}
.admin_right{
    width: calc(100% - 166px);
    height: 100%;
    overflow-y: auto;
}