.App{
    height: 100%;
    width: 100%;
}

/*定义了滚动条整体样式；*/ 
::-webkit-scrollbar{
    width: 4px !important;
  }
          
  /*定义了滚动条滑块的样式*/
::-webkit-scrollbar-thumb {
    border-radius: 0;
    border-style: solid;
    background-color: rgba(150, 144, 142, 0.4);
    border-color: rgb(17, 5, 187);
    border-width: 1px;
    background-clip: padding-box;
  }
          
    /*定义了轨道的样式*/ 
::-webkit-scrollbar-track {/*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 10px;
    background: #EDEDED;
  }	
::-webkit-scrollbar-thumb:hover {
  background: rgba(157, 165, 183, 0.7)
  }
  
  .loading{
    color: rgb(177, 66, 66);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 2;
  }


/* 表头样式 */
.ant-table-thead>tr>th,
.ant-table-thead>tr {
  background: #ccd5d7 !important;
  /* color: #d0dcdc !important; */
  font-size: 20px;
  /* line-height: 0px; */
  font-weight: bold !important;
  /* text-align: center !important; */
}
/* 表头行高调整 */
.ant-table-thead>tr>th {
  line-height: 30px;
}

.ant-table-tbody>tr>td {
    padding: 10px;
}
/* hover悬浮效果样式  选中样式 */
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td,
.ant-table-row-hover,
.ant-table-row-hover>td {
  background: #cbe0e1 !important;
}